import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";


export default function CityImagining() {
  return (
    <Layout pageTitle="A City Imagining">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>City Imagining - USI Projects</title>
        <meta name="description" content="USI have been supporting Belfast City Council on the development of the new 10 year cultural strategy 'A City Imagining'."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
      
      <section class="text-gray-700 body-font heading-container-imagining">

      <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">A City Imagining</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                Belfast City Council</p>
              </div>
          </section>
        </div>
      </section>

      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-pink-900">Overview</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl ">USI have
                  been supporting Belfast City Council on the development of the new 10 year cultural strategy 'A City
                  Imagining'. In the summer of 2019, USI designed and delivered a pop-up public consultation space in an
                  empty shop on the high street of Royal Avenue in Belfast City Centre.<br/><br/>

                  This consultation space aimed to make the key priorities from the new strategy tangible to the people
                  of Belfast through interactive and engaging activities. Structured workshops and conversations about
                  the future of the city were held and a drop-in space was created for shoppers and pedestrians to
                  discover the Cultural Strategy. </p>

              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase">Fields of
                  Practice</p>
                <p class="my-4 text-gray-500 leading-relaxed">Culture<br/>
                  Regeneration<br/>Communities<br/>Placemaking</p>
                <p class="text-2xl font-bold uppercase">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed">Citizen Engagement<br/>
                  Design Proposals<br/>Policy Alignment<br/>Strategy Development</p>
                <p class="text-2xl font-bold uppercase">Location</p>
                <p class="my-4 text-gray-500 leading-relaxed ">Belfast</p>
              </div>
            </div>
          </section>
        </div>
      </section>


      <div class="md:p-16 md:m-16">
        <video controls
            src="https://player.vimeo.com/external/363420525.hd.mp4?s=df443a23e1cdd612b69e398c66485471eee322d3&profile_id=175">
        </video>
      </div>



      <div class="flex flex-wrap md:mb-0">
        <div class="h-auto w-full md:w-1/2 md:mb-0">
          <img class="w-full h-full px-2 py-2" src="https://ik.imagekit.io/usi/project-pages/shop-left_T1v1f_tCZirks.png"
            alt="" width="720px"/></div>
        <div class="h-auto w-full md:w-1/2 md:mb-0 px-2 py-2">
          <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/shop-right_aNUhf5zwg.png" alt=""
            width="720px"/></div>
      </div>



      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="w-full md:px-40 xl:w-4/5 py-4 xl:px-32 md:mb-0">

                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="text-xl md:text-2xl lg:text-3xl  md:pt-9 p-6 md:pl-0 md:pr-0 md:pb-0 md:mr-20">
                  Over the course of 5 weeks, the Hub hosted more than 45 events and welcomed over 1,100 people to help
                  build and grow the Cultural Strategy through conversation and engagement. One quick activity, the
                  ‘data-string wall’, involved tying a string onto a peg and following the string across statements on
                  the wall that resonated the most with people for the future of Belfast. This sparked an initial
                  conversation with visitors which led to further discussion about the themes and intentions of the
                  cultural strategy. </p>


              </div>
              <div data-scroll data-scroll-speed="1" class="w-full xl:w-4/5 py-4 sm:px-8 md:px-24 mb-4 md:mb-0">


              </div>
            </div>
          </section>
        </div>
      </section>





      <img data-scroll data-scroll-speed="2" data-scroll-direction="horizontal" data-scroll-class="scroll-class"
        class="w-full md:pl-20 md:pr-20" src="../img/projects/Data Wall_A 1.png" alt=""/>



      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="w-full md:px-40 xl:w-4/5 py-4 xl:px-32 md:mb-0">

                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="text-xl md:text-2xl lg:text-3xl  md:pt-9 p-6 md:pl-0 md:pr-0 md:pb-0 md:mr-20">
                  The results of this public consultation process have laid a strong foundation for the implementation
                  of the strategy and the ongoing co-design of cultural programmes in the city. We are continuing to
                  help Belfast City Council involve the people of Belfast in shaping the development of the city for the
                  years to come.
                </p>


              </div>
              <div data-scroll data-scroll-speed="1" class="w-full xl:w-4/5 py-4 sm:px-8 md:px-24 mb-4 md:mb-0">


              </div>
            </div>
          </section>
        </div>
      </section>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pb-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div data-scroll data-scroll-speed="2" class="w-full md:px-40 xl:w-2/3 py-4 xl:px-32 md:mb-0">

                <img src="../img/projects/imagining-stats.svg" alt=""/>

              </div>
              <div data-scroll data-scroll-speed="1" data-scroll-direction="horizontal"
                class="w-full xl:w-1/3 mt-36  sm:px-8 md:px-24 md:mb-0">

                <img src="../img/projects/imagining-person.svg" alt=""/>

              </div>
            </div>
          </section>
        </div>
      </section>




      <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/02_Old_Workshop__2__1_zE65hQrShncbT.png" alt=""/>



      <img class="w-full sm:p-20"
        src="https://ik.imagekit.io/usi/project-pages/imagining-group_99BqpF-0FhUz.png" alt=""></img>

<div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-pink-900">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../cop26" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
